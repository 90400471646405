<template>
  <base-layout>
    <div class="day-selector" :style="{'background':primaryColor + '!important'}">
      <div class="day-selector-item" v-for="(value, key) in days" :key="key" :value="key" :style="{'background':primaryColor + '!important'}"
           style="" mode="md"  @click="activeDay = key">
        <div class="day-text d-md-none">  {{moment(key).format('ddd')}}  </div>
        <div v-if="activeDay == key" class="day-number-selected">
          <span class="punkt-selected d-md-none">{{ moment(key).format('D') }} </span>
          <span style="color:white" class="d-none d-md-block large-selected">{{moment(key).format('dddd, MMMM D')}}</span>
        </div>
        <div v-else class="day-number">
          <span class="d-md-none">{{ moment(key).format('D') }} </span>
          <span style="color:white" class="d-none d-md-block">{{moment(key).format('dddd, MMMM D')}}</span>
        </div>
      </div>
    </div>
    <ion-content id="type-detail-page" class="has-header has-subheader">
      <div style="padding: 10px; font-size: 18px; text-align: center" class="mb-2"
           :style="{'background-color': type.color, 'color': type.font_color}">{{ type.name }}
      </div>
      <session-list :sessions="days[activeDay].sessions"
                    v-if="days[activeDay] && days[activeDay].sessions"></session-list>

    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent} from "@ionic/vue";
import {mapActions, mapState} from "vuex";
import SessionList from "@/components/SessionList";
import moment from "moment";


export default {
  data() {
    return {
      type: {},
      sessions: [],
      days: [],
      activeDay: '',

    };
  },
  components: {
    IonContent,
    "session-list": SessionList
  },
  computed:{
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion', 'primaryColor']),
  },
  methods: {
    ...mapActions("sessiontypes", ["getType", "getSessionsByType"]),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    groupList(list, selector) {
      let letters = [];
      let currentCharCode = '2010-01-01T00:00:00'; //$filter('date')(date, format, timezone)
      let sortedList = [];

      function addLetter(code) {
        let letter = code
        sortedList.push({
          isHead: true,
          head: letter,
          start_time: letter
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector];
          let difference = new Date(itemCharCode) - new Date(currentCharCode);
          if (difference > 0)
            addLetter(itemCharCode);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });
      return sortedList;
    },
    prepareDays(sessions) {
      for (let i = 0; i < sessions.length; i++) {
        let session = sessions[i];
        if (session.start_time) {
          let start = session.start_time.split('T')[0];
          if (!this.activeDay) {
            this.activeDay = start;
          }
          if (!this.days[start]) {
            this.days[start] = {};
            this.days[start].label = moment(start).format('dddd, D. MMMM')
            this.days[start].sessions = [];
          }
          this.days[start].sessions.push(session);
        }
      }
    },

  },
  async created() {
    this.moment = moment;
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(route) {
        if (route.name == 'Sessiontype Detail') {
          this.type = await this.getType(this.$route.params.id);
          this.trackWithLabel(this.type.name)
          let sessions = (await this.getSessionsByType(this.type.id)).sort((a, b) => {
            return new Date(a.start_time) - new Date(b.start_time);
          });

          this.sessions = this.groupList(sessions, 'start_time');
          this.days = {};
          this.prepareDays(this.sessions);

        }
      }
    }
  }
};
</script>
<style scoped>
.day-selector{
  width: 100%;
  padding: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.day-selector:hover{
  cursor: pointer;
}

.day-selector-item{
  text-align: center;
  width: 100%;
  position: relative;
  display: block;
}


.day-text{
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
}
.day-number{
  width: 100%;
  color: white;

}
.day-number-selected{
}

.punkt-selected {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}
.large-selected{
  font-weight: 900;
}
#type-detail-page {
  --padding-bottom: 10px;
}
</style>
